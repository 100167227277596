import React from 'react'
import { Link } from 'gatsby'
import { blogCard, blogLink, blogCardTitle, blogCardDate, blogCardExcerpt } from "./post-link.module.css"

const PostLink = ({ post }) => {

  console.log(post.excerptAst.children[0].children[0].value);
  const excerpt = post.excerptAst.children[0].children[0].value;

  return (<div className={blogCard}>
    <Link className={blogLink} to={post.frontmatter.slug}>
      <h2 className={blogCardTitle}>{post.frontmatter.title}</h2>
      <p className={blogCardDate}><small>Published on {post.frontmatter.date}. Read in {post.timeToRead} minutes.</small></p>
      <p className={blogCardExcerpt}>{excerpt}</p>
    </Link>
    
  </div>)

}

  

export default PostLink