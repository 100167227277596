import React from 'react'
import { graphql } from 'gatsby'
import PostLink from '../components/post-link'
import Layout from '../layout/layout'

const FindingsPage = ({
  data: {
    allMarkdownRemark: { edges },
  },

}) => {

  const Posts = edges
  .map( edge => <PostLink key={edge.node.id} post={edge.node} /> )

  return (
    <Layout pageTitle="FINDINGS - JVR RESEARCH" pageHeading="FINDINGS">
      <div>{ Posts }</div>
    </Layout>
  )
  
}

export default FindingsPage


export const pageQuery = graphql`
  query{
    allMarkdownRemark( sort: { order: DESC, fields: [frontmatter___date] }) {
      edges { 
        node {
          id
          excerptAst(pruneLength: 1)
          timeToRead
          frontmatter {
            date(formatString: "MMM DD, YYYY")
            slug
            title
          }
        }
      }
    }
  }
`