import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import { container, header, heading, navLinks, navLinkItem, navLinkText } from './layout.module.css'
console.log("container: ", container)


const Layout = ({ pageTitle, pageHeading, children }) => {

  return (
    <main className={container}>

      <header className={header}>
        <title>{ pageTitle }</title>
        <StaticImage src="../images/SOLGods1891.png" alt="SOLGods 1891 - Solana NFT" loading="eager" placeholder="blurred"
      layout="constrained" width={50} height={50} />
      <h1 className={heading}>{ pageHeading }</h1>
        <nav>
          <ul className={navLinks}>
            <li className={navLinkItem}><Link to="/" className={navLinkText}>Home</Link></li>
            <li className={navLinkItem}><Link to="/findings" className={navLinkText}>Findings</Link></li>
          </ul>
        </nav>
        
      </header>

      {children}

      <footer>

      </footer>

    </main>
  )
}

export default Layout